import { IConfig, IConfiguration } from '@microsoft/applicationinsights-web';
import packageJson from '../../package.json'
import { resources } from './resources';

export const environment = {
  production: false,
  applicationInsights: {
    baseConfig: {
      disableCorrelationHeaders: false,
      enableAjaxErrorStatusText: true,
      // Setting this to false since it is not working as expected
      enableAutoRouteTracking: false,
      instrumentationKey: '7bdd5891-6662-4353-bc62-26f00ece9795'
    } as IConfig & IConfiguration,
    filterOutSuccessfulDependencies: true
  },
  b2c: {
    baseAuthority: 'b2c.frontsystems.com',
    clientId: 'df267786-6b73-4005-8f25-3795ad1278cb',
    policies: {
      signIn: 'B2C_1A_SIGNINWITHSELFSERVICEPASSWORDRESET_EXTERNAL_DEVELOPMENT'
    },
    protectedResources: resources.dev,
    tenantId: '5a9ada72-018a-4c33-a9f6-25cab8d8ff37'
  },
  defaultProductImgUrl: '/images/shop-placeholder.png',
  sellThroughReportStorage: 'sell-through-report-storing-v0.9.1',
  sellThroughReportDatesStorage: 'sell-through-dates-report-storing-v0.9.1',
  salesReportStorage: 'sales-report-storing-v0.9.1',
  salesReportDatesStorage: 'sales-dates-report-storing-v0.9.1',
  salesReportCompareByStorage: 'sales-report-compare-by-storing-v0.9.1',
  salesReportSettingsStorage: 'sales-report-settings-storing-v0.9.1',
  topProductStorage: 'top-products-report-storing-v0.9.1',
  topProductDatesStorage: 'top-products-dates-report-storing-v0.9.1',
  stockReportStorage: 'stock-report-storing-v0.9.1',
  stockReportDatesStorage: 'stock-dates-report-storing-v0.9.1',
  loginApp: 'https://login-stage.frontsystems.no',
  adminLoginApp: 'https://login-stage.frontsystems.no',
  version: packageJson.version,
  build: '20250214.15'
};
